import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import { FaPlus, FaSearch } from "react-icons/fa";
import Filter from "../../../Filter";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import instance from "../../../../axios";
import { useDebounce } from "use-debounce";
import Pagination from "../../../Pagination";

//search filter
const getSearchFilter = (searchName, clients) => {
  if (!searchName) {
    return clients;
  }
  return clients.filter((clients) =>
    clients.clientname.toLowerCase().includes(searchName.toLowerCase())
  );
};

const Clients = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [count, setCount] = useState();
  const [clients, setClients] = useState([]);
  const [countries, setCountries] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [searchName, setSearchName] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [sortedClients, setSortedClients] = useState("");
  const [country, setCountry] = useState("");

  const [searchFilterData, setSearchFilterData] = useState(true);
  const [sortFilterData, setSortFilterData] = useState(false);

  const searchFilter = getSearchFilter(searchName, clients);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const params = { page, limit, search, sort, country };
        const clientsResponse = await instance.get(`/client`, {
          params,
        });
        const countriesResponse = await instance.get(`/country/`);
        setClients(clientsResponse.data.clients);
        setCount(clientsResponse.data.total);
        setCountries(countriesResponse.data.countries);
      } catch (err) {
        if (err.response) {
          setError({ type: "error", message: err.response.data.err });
        } else {
          setError({ type: "error", message: err.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, search, sort, country]);

  const [filterOpen, setFilterOpen] = useState(false);
  const navigate = useNavigate();

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div
      className={`transition-all min-h-[calc(100vh-65px)] ${
        filterOpen ? "ml-44" : "ml-16"
      } py-4 flex flex-col space-y-2`}
    >
      <Filter
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        clear={() => setCountry(null)}
      >
        <select
          id="speciality"
          name="speciality"
          className=""
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        >
          <option selected disabled value="" className="text-secondary">
            Select Country
          </option>
          {countries.map((country) => (
            <option value={country?._id} key={country?._id}>
              {country.countryName}
            </option>
          ))}
        </select>
      </Filter>
      <div className="flex justify-between items-center my-8">
        <h1 className="text-2xl">System Clients</h1>
        {/* <div className="">FILTERS</div> */}
      </div>
      <div className="flex items-center justify-between">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(e.target.search.value);
          }}
          className="w-1/3 relative"
        >
          <button type="submit" className="absolute top-3 right-3">
            <FaSearch />
          </button>
          <input
            name="search"
            type="text"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            placeholder={"Search By Name or ID"}
          />
        </form>
        <button
          onClick={() => navigate("/addclient")}
          className="inline-flex items-center rounded-md border px-3 py-2 text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
        >
          <FaPlus className="mr-2" /> Add New Client
        </button>
      </div>
      <div className="flex justify-end">
        <select
          id="role"
          name="role"
          className="w-1/4 mt-2"
          value={sort}
          onChange={(e) => {
            setSort(e.target.value);
          }}
        >
          <option selected disabled value="" className="text-secondary">
            Sort By
          </option>
          <option value="completed">Completed Tasks</option>
          <option value="profit">Profit</option>
        </select>
      </div>
      <div className="mt-2 overflow-x-auto drop-shadow rounded-sm">
        {clients && clients.length > 0 ? (
          <>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 text-cyan-600">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                  >
                    Country
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                  >
                    Task Count
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                  >
                    Completed Tasks
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                  >
                    Total Gain
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                  >
                    Total Profit
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-center"
                  >
                    Currency
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {clients.map((client) => (
                  <tr key={client?._id}>
                    <td className="px-6 py-2 whitespace-nowrap text-center">
                      <Link
                        className="text-indigo-600 hover:text-indigo-900 font-medium"
                        to={`/client/${client?._id}`}
                      >
                        {client.clientname}
                      </Link>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-center">
                      {client.country.countryName}
                    </td>

                    <td className="px-6 py-2 whitespace-nowrap text-center">
                      {client.tasksCount}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-center">
                      {client.completedCount}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-center">
                      {client.totalGain}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-center">
                      {client.totalProfit}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-center">
                      {client.currency && client.currency.currencyname}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={page}
              totalPages={Math.ceil(count / limit)}
              onNextPage={() => setPage(page + 1)}
              onPreviousPage={() => setPage(page - 1)}
              onFirstPage={() => setPage(1)}
              onLastPage={() => setPage(Math.ceil(count / limit))}
            />
          </>
        ) : (
          <div className="row p-3 m-0 text-center">
            <h2 className="text-lg font-medium text-gray-900">
              There Are No Clients
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Clients;
