import React, { useEffect, useReducer, useState } from "react";

import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import {
  validate,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../../../util/validators";

import { Link, useNavigate, useParams } from "react-router-dom";
import { RiDeleteBinFill } from "react-icons/ri";
import { FaTasks } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import { FaCcVisa } from "react-icons/fa";
import { AiOutlineFileDone } from "react-icons/ai";
import { GiProfit } from "react-icons/gi";
import { FiFilter } from "react-icons/fi";
import DateFilterComponent from "../../../DateFilter";
import TasksTable from "../../../TasksTable/TasksTable";
import instance from "../../../../axios";

//fullName validation
const fullNameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.fullName,
        isvalid: validate(action.fullName, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

//number validation
const numberReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.number,
        isvalid: validate(action.number, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//email validation
const emailReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.email,
        isvalid: validate(action.email, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//country validation
const countryReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.country,
        isvalid: validate(action.country, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const FreeLancerDetails = () => {
  const [tasksCount, setTasksCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalGain, setTotalGain] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [editFull, setEditFull] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState(null);

  let { id } = useParams();

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [withoutFilterData, setwithoutFilterData] = useState(true);
  const [dateFilterData, setDateFilterData] = useState(false);

  const [freeLancer, setFreeLancer] = useState([]);
  const [countries, setCountries] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [freeLancerAccount, setFreeLancerAccount] = useState();
  const [freeLancerTasks, setFreeLancerTasks] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] = useState({ start, end });
  const [paymentMethod, setPaymentMethod] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [freelancerSpecialities, setFreelancerSpecialities] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const tasksResponse = await instance.get(`/task`, {
          params: {
            freelancer: id,
            page,
            limit,
            start: selectedRange.start,
            end: selectedRange.end,
            isPage: true,
          },
        });
        const freelancerResponse = await instance.get(`/freeLancer/${id}`, {
          params: { page, limit },
        });
        const specialityResponse = await instance.get(`/speciality/`);
        const currencyResponse = await instance.get(`/currency/valid/list`);
        const countryResponse = await instance.get(`/country/`);
        setTasksCount(tasksResponse.data.tasksCount);
        setTotalGain(tasksResponse.data.totalGain);
        setTotalProfit(tasksResponse.data.totalProfit);
        setCompletedCount(tasksResponse.data.completedTasksCount);
        setTotalCost(tasksResponse.data.totalCost);
        setFreeLancerTasks(tasksResponse.data.tasks);
        setFreeLancer(freelancerResponse.data.freelancer);
        setFreeLancerAccount(freelancerResponse.data.freelancerAccount);
        setFreelancerSpecialities(
          freelancerResponse.data.freelancer.speciality
        );
        setSpecialities(specialityResponse.data.specialities);
        setCurrencies(currencyResponse.data.currencies);
        setCountries(countryResponse.data.countries);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, selectedRange]);

  //speciality value
  const specialityChangeHandler = (e) => {
    const sub = {
      _id: e.target.value.split("-")[0],
      sub_speciality: e.target.value.split("-")[1],
    };
    if (freelancerSpecialities.find((s) => s?._id === sub?._id)) {
      setFreelancerSpecialities((prev) =>
        prev.filter((item) => item?._id !== sub?._id)
      );
    } else {
      setFreelancerSpecialities((prev) => [...prev, sub]);
    }
    e.target.value = "";
  };

  //fullName validation
  const [fullNameState, dispatch2] = useReducer(fullNameReducer, {
    value: freeLancer.freelancername,
    isvalid: false,
    isTouched: false,
  });

  const fullNameChangeHandler = (event) => {
    dispatch2({
      type: "CHANGE",
      fullName: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const fullNameTouchHandler = () => {
    dispatch2({
      type: "TOUCH",
    });
  };

  //Number validation
  const [numberState, dispatch5] = useReducer(numberReducer, {
    value: freeLancer.phone,
    isvalid: false,
    isTouched: false,
  });

  const numberChangeHandler = (event) => {
    dispatch5({
      type: "CHANGE",
      number: event.target.value,
      validators: [VALIDATOR_MINLENGTH(11)],
    });
  };
  const numbertouchHandler = () => {
    dispatch5({
      type: "TOUCH",
    });
  };

  //email validation
  const [emailState, dispatch7] = useReducer(emailReducer, {
    value: freeLancer.email,
    isvalid: false,
    isTouched: false,
  });

  const emailChangeHandler = (event) => {
    dispatch7({
      type: "CHANGE",
      email: event.target.value,
      validators: [VALIDATOR_EMAIL()],
    });
  };
  const emailTouchHandler = () => {
    dispatch7({
      type: "TOUCH",
    });
  };

  //country value
  const [country, setCountry] = useState("");
  const countryChangeHandler = (newOne) => {
    setCountry(newOne);
  };

  const [currency, setCurreny] = useState(
    freeLancer.currency && freeLancer.currency.currencyname
  );
  const [userSpeciality, setUserSpeciality] = useState(
    freeLancer.speciality && freeLancer.speciality.sub_speciality
  );
  const [language, setLanguage] = useState(
    freeLancer.language ? freeLancer.language : ""
  );

  //////////////////////////////////////
  const editFreeLancerHandler = async (event) => {
    event.preventDefault();
    const body = {
      name: fullNameState.value || freeLancer.freelancername,
      email: emailState.value || freeLancer.email,
      country: country || freeLancer.country?._id,
      phone: numberState.value || freeLancer.phone,
      currency: currency || freeLancer.currency?._id,
      method: paymentMethod || freeLancerAccount[0]?.method,
      accountNumber: accountNumber || freeLancerAccount[0]?.accountNumber,
      language: language || freeLancer.language,
      specialities: freelancerSpecialities.map((s) => s?._id),
    };
    try {
      setIsLoading(true);
      setError(null);
      const response = await instance.post(
        `/freelancer/${freeLancer?._id}`,
        body
      );
      if (password && password !== "") {
        await instance.post(`/freelancer/password/${freeLancer?._id}`, {
          password,
        });
      }
      const responseData = await response;
      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }
      setError({ type: "success", message: responseData.data.message });
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      if (err.response) {
        setError({ type: "error", message: err.response.data.err });
      } else {
        setError({ type: "error", message: err.message });
      }
      setIsLoading(false);
    }
  };

  //delete user
  const deleteFreeLancerHandler = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/freelancer/${id}`
        //  ,
        //  { headers :{
        //     'Authorization':`Bearer ${token}`
        //   }
        // }
      );
      const responseData = await response;

      setError(responseData.data.message);
      setIsLoading(false);
      window.location.href = "/freelancers";
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  };
  //error message
  const errorHandler = () => {
    setError(null);
    window.location.reload(true);
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  useEffect(() => {
    if (start && end) {
      setSelectedRange({ start, end });
      setPage(1);
    }
  }, [start, end]);

  const onFirstPage = () => {
    setPage(1);
  };
  const onLastPage = () => {
    setPage(Math.ceil(tasksCount / limit));
  };
  const onNextPage = () => {
    setPage((prev) => prev + 1);
  };
  const onPreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)]">
      <ErrorModal message={error} onClear={() => setError(null)} />

      <div className="relative flex items-center justify-between w-full p-1 mb-4">
        {/* <button
          className="absolute top-0 left-0 p-2 text-3xl"
          onClick={() => navigate("/freelancers")}
        >
          <TiArrowBack />
        </button> */}
        <h2 className="text-center text-2xl font-bold lg:text-3xl">
          Freelancer Details
        </h2>
        <div>
          <DateFilterComponent
            startDate={start}
            endDate={end}
            onChange={onDateChange}
          />
        </div>
      </div>

      <div className="relative flex items-center justify-betwen border-2 space-x-8 rounded-md shadow pr-4 py-4 bg-[#F4F7FC]">
        <button
          className="absolute top-2 right-1.5 w-10 h-10"
          onClick={deleteFreeLancerHandler}
        >
          <RiDeleteBinFill className="text-gray-400 w-10 h-10" />
        </button>

        <div className="w-1/2 grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <h3 className="text-lg font-medium text-gray-900">Full Name:</h3>
            <p
              className={
                !editFull ? "text-lg font-medium text-gray-700" : "hidden"
              }
            >
              {freeLancer.freelancername}
            </p>
            <input
              type="text"
              className={`${
                editFull
                  ? "block w-full rounded-md border-gray-300 p-2 focus:ring-indigo-500 focus:border-indigo-500"
                  : "hidden"
              } ${
                !fullNameState.isvalid &&
                fullNameState.isTouched &&
                "border-red-500"
              }`}
              placeholder={freeLancer.freelancername}
              value={fullNameState.value}
              onChange={fullNameChangeHandler}
              onBlur={fullNameTouchHandler}
              isvalid={fullNameState.isvalid.toString()}
            />
          </div>
          {/* ... other form fields with similar styling ... */}
          <div className="col-span-1">
            <h3 className="text-lg font-medium text-gray-900"> Email:</h3>
            <p
              className={
                !editFull ? "text-lg font-medium text-gray-700" : "hidden"
              }
            >
              {freeLancer.email}
            </p>
            <input
              type="email"
              className={`${
                editFull
                  ? "block w-full rounded-md border-gray-300 p-2 focus:ring-indigo-500 focus:border-indigo-500"
                  : "hidden"
              } ${
                !emailState.isvalid && emailState.isTouched && "border-red-500"
              }`}
              placeholder={freeLancer.email}
              value={emailState.value}
              onChange={emailChangeHandler}
              onBlur={emailTouchHandler}
              isvalid={emailState.isvalid.toString()}
            />
          </div>
          <div className="col-span-1">
            <h3 className="text-lg font-medium text-gray-900">Phone:</h3>
            <p
              className={
                !editFull ? "text-lg font-medium text-gray-700" : "hidden"
              }
            >
              {freeLancer.phone}
            </p>
            <input
              type="number"
              className={`${
                editFull
                  ? "block w-full rounded-md border-gray-300 p-2 focus:ring-indigo-500 focus:border-indigo-500"
                  : "hidden"
              } ${
                !numberState.isvalid &&
                numberState.isTouched &&
                "border-red-500"
              }`}
              placeholder={freeLancer.phone}
              value={numberState.value}
              onChange={numberChangeHandler}
              onBlur={numbertouchHandler}
              isvalid={numberState.isvalid.toString()}
            />
          </div>
          {editFull && (
            <div className="col-span-1">
              <h3 className="text-lg font-medium text-gray-900">Password :</h3>
              <div className={editFull ? "inline py-2" : "hidden"}>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Unchanged"
                  name="password"
                  id="password"
                  className={`${
                    editFull
                      ? "block w-full rounded-md border-gray-300 p-2 focus:ring-indigo-500 focus:border-indigo-500"
                      : "hidden"
                  }`}
                />
              </div>
            </div>
          )}

          <div className="col-span-1">
            <h3 className="text-lg font-medium text-gray-900">Country:</h3>
            <p
              className={
                !editFull ? "text-lg font-medium text-gray-700" : "hidden"
              }
            >
              {freeLancer.country && freeLancer.country.countryName}
            </p>
            <div className={editFull ? "inline py-2" : "hidden"}>
              <select
                id="speciality"
                name="speciality"
                className="w-full rounded-md border-gray-300 p-2 focus:ring-indigo-500 focus:border-indigo-500"
                value={country}
                onChange={(event) => countryChangeHandler(event.target.value)}
              >
                <option selected disabled value="" className="text-secondary">
                  Previous:{" "}
                  {freeLancer.country && freeLancer.country.countryName}
                </option>
                {countries.map((country) => (
                  <option value={country?._id} key={country?._id}>
                    {country.countryName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-span-1">
            <h3 className="text-lg font-medium text-gray-900">Language:</h3>
            <p
              className={
                !editFull ? "text-lg font-medium text-gray-700" : "hidden"
              }
            >
              {freeLancer.language && freeLancer.language}
            </p>
            <div className={editFull ? "inline py-2" : "hidden"}>
              <select
                id="language"
                name="language"
                className="w-full rounded-md border-gray-300 p-2 focus:ring-indigo-500 focus:border-indigo-500"
                value={language}
                onChange={(event) => setLanguage(event.target.value)}
              >
                <option selected disabled value="" className="text-secondary">
                  Previous: {freeLancer.language && freeLancer.language}
                </option>
                <option value="all">All</option>
                <option value="arabic">Arabic</option>
                <option value="english">English</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          <div className="col-span-1">
            <h3 className="text-lg font-medium text-gray-900">Currency:</h3>
            <p
              className={
                !editFull ? "text-lg font-medium text-gray-700" : "hidden"
              }
            >
              {freeLancer.currency && freeLancer.currency.currencyname}
            </p>
            <div className={editFull ? "inline py-2" : "hidden"}>
              <select
                id="Currency"
                name="Currency"
                className="w-full rounded-md border-gray-300 p-2 focus:ring-indigo-500 focus:border-indigo-500"
                value={currency}
                onChange={(event) => setCurreny(event.target.value)}
              >
                <option selected disabled value="" className="text-secondary">
                  Previous:{" "}
                  {freeLancer.currency && freeLancer.currency.currencyname}
                </option>
                {currencies.map((currency) => (
                  <option value={currency?._id} key={currency?._id}>
                    {currency.currencyname}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-span-2 space-y-2">
            <div className="flex justify-between items-center">
              <h1 className="text-lg font-medium text-gray-900">
                Specialities:
              </h1>
              {editFull && (
                <select className="w-1/2" onChange={specialityChangeHandler}>
                  <option value="">Select Specialities</option>
                  {specialities?.map((s) => (
                    <optgroup key={s.main} label={s.main}>
                      {s?.sub?.map((ss) => (
                        <option
                          key={ss?._id}
                          value={`${ss?._id}-${ss.sub_speciality}`}
                        >
                          {ss.sub_speciality}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </select>
              )}
            </div>
            <div className="flex flex-wrap gap-1">
              {freelancerSpecialities.length > 0 ? (
                freelancerSpecialities?.map((s) => (
                  <div
                    key={s?._id}
                    className="relative bg-white py-1 px-2 rounded-md drop-shadow"
                  >
                    {editFull && (
                      <button
                        onClick={() =>
                          setFreelancerSpecialities((prev) =>
                            prev.filter((item) => item?._id !== s?._id)
                          )
                        }
                        className="absolute right-0 -top-2 text-red-500"
                      >
                        x
                      </button>
                    )}
                    {s.sub_speciality}
                  </div>
                ))
              ) : (
                <div>No Specialities</div>
              )}
            </div>
          </div>

          {editFull && (
            <>
              <div className="flex flex-col w-full">
                <label className="w-full font-bold">Payment Method</label>
                <input
                  type="text"
                  placeholder={freeLancerAccount[0]?.method || "Payment Method"}
                  value={paymentMethod}
                  onChange={(event) => setPaymentMethod(event.target.value)}
                  className={`${
                    editFull
                      ? "block w-full rounded-md border-gray-300 p-2 focus:ring-indigo-500 focus:border-indigo-500"
                      : "hidden"
                  }`}
                />
              </div>

              <div className="flex flex-col w-full">
                <label className="w-full font-bold">Account Number</label>
                <input
                  type="text"
                  placeholder={
                    freeLancerAccount[0]?.accountNumber || "Account Number"
                  }
                  value={accountNumber}
                  onChange={(event) => setAccountNumber(event.target.value)}
                  className={`${
                    editFull
                      ? "block w-full rounded-md border-gray-300 p-2 focus:ring-indigo-500 focus:border-indigo-500"
                      : "hidden"
                  }`}
                />
              </div>
            </>
          )}

          <div className="col-span-2 flex items-center justify-center space-x-2">
            {!editFull && (
              <button
                className="bg-cyan-600 rounded-sm transition-all hover:bg-cyan-400 text-white px-12 py-1"
                onClick={() => setEditFull(!editFull)}
              >
                Edit
              </button>
            )}
            {editFull && (
              <>
                <button
                  // disabled={
                  //   !fullNameState.isvalid ||
                  //   !numberState.isvalid ||
                  //   !emailState.isvalid ||
                  //   !country ||
                  //   !currency
                  // }
                  className="bg-green-500 rounded-sm transition-all hover:bg-green-400 text-white px-3 py-1"
                  onClick={editFreeLancerHandler}
                >
                  Save
                </button>
                <button
                  className="bg-red-500 rounded-sm transition-all hover:bg-red-400 text-white px-3 py-1"
                  onClick={() => setEditFull(!editFull)}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <div className="grid grid-cols-2 gap-2">
            {/* Tasks Count */}
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <FaTasks className="bg-blue-100 text-blue-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">Tasks Count</h6>
                <h4 className="font-light ml-1 my-0 p-0">{tasksCount}</h4>
              </div>
            </div>

            {/* Completed Count */}
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <AiOutlineFileDone className="bg-orange-100 text-orange-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">
                  Completed Count
                </h6>
                <h4 className="font-light ml-1 my-0 p-0">{completedCount}</h4>
              </div>
            </div>

            {/* FreeLancer Cost */}
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <FaCoins className="bg-red-100 text-red-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">
                  FreeLancer Cost
                </h6>
                <h4 className="font-light ml-1 my-0 p-0">{totalGain}</h4>
              </div>
            </div>

            {/* Total Profit */}
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <GiProfit className="bg-green-100 text-green-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">Total Profit</h6>
                <h4 className="font-light ml-1 my-0 p-0">{totalProfit}</h4>
              </div>
            </div>

            {/* Account Details */}
            <div className="col-span-2 w-1/2 bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2 mx-auto">
              <FaCcVisa className="bg-purple-100 text-purple-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">
                  Account Details
                </h6>
                <h4 className="font-light ml-1 my-0 p-0">
                  {freeLancerAccount &&
                    freeLancerAccount.map((acc) => (
                      <div className="text-base" key={acc?._id}>
                        <Link to={`/account/${acc?._id}`} className="">
                          Click Here
                        </Link>
                      </div>
                    ))}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-3 drop-shadow">
        {freeLancerTasks && freeLancerTasks && freeLancerTasks.length > 0 ? (
          <TasksTable
            pagination
            page={page}
            totalPages={Math.ceil(tasksCount / limit)}
            onFirstPage={onFirstPage}
            onLastPage={onLastPage}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            tasks={freeLancerTasks}
          />
        ) : (
          <div className="row col-12  p-2 text-center">
            <h3 className=" text-danger edit-form-lable">
              This User Didn't Do Any Tasks Yet
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default FreeLancerDetails;
