import React, { useEffect, useState, useReducer } from "react";
import { validate, VALIDATOR_MINLENGTH } from "../../../../util/validators";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";

import { Link, useNavigate, useParams } from "react-router-dom";
import { RiDeleteBinFill } from "react-icons/ri";
import { TiArrowBack } from "react-icons/ti";
import { IoMdDownload, IoMdRemoveCircle } from "react-icons/io";
import { MdCancel, MdEdit } from "react-icons/md";

import GetCookie from "../../../../hooks/getCookie";
import EditTask from "./EditTask";
import Status from "./Status";
import { getStatusClass, getRowClass } from "../../../../util/colors";
import Attachments from "../../../Attachments";
import io from "socket.io-client";
import Notes from "../../../Notes";
import { emitTaskNotification } from "../../../../util/io";
import { format } from 'date-fns';

//Comment validation
const commentReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.comment,
        isvalid: validate(action.comment, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const TaskDetails = () => {
  const token = GetCookie("AdminToken");
  const userId = JSON.parse(localStorage.getItem("AdminData"));
  const role = JSON.parse(localStorage.getItem("user"))?.user_role;
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  let { id } = useParams();

  const [editTask, setEditTask] = useState(false);
  const [task, setTask] = useState();
  const [offer, setOffer] = useState("");
  const [percentages, setPercentages] = useState([]);
  const [notes, setNotes] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [comments, setComments] = useState([]);
  const [client, setClient] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [user, setUser] = useState([]);
  const [status, setStatus] = useState([]);
  const [currency, setCurrency] = useState([]);

  const [statuses, setStatuses] = useState([]);
  const [changeStatus, setChangeStatus] = useState("");
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm');
  };

  useEffect(() => {
    let timerId;
    if (loading) {
      setIsLoading(true);
      timerId = setTimeout(async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}:5001/api/task/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setTask(res.data.task);
            setOffer(res.data.offer);
            setClient(res.data.task.client);
            setCurrency(res.data.task.task_currency);
            setSpeciality(res.data.task.speciality);
            setStatus(res.data.task.taskStatus);
            setUser(res.data.task.created_by);
            setPercentages(res.data.percentages);
            
            setNotes(res.data.notes);
            setReviews(res.data.reviews);
            setComments(res.data.comments);
          });
        setLoading(false);
        setIsLoading(false);
      });
      timerId = setTimeout(async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}:5001/api/status/`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setStatuses(res.data.statuses);
          });
      });
    }
    return () => clearTimeout(timerId);
  }, [loading]);

  /////////////////////////////////////////
  //Change State
  const changeStatusHandler = async (event) => {
    event.preventDefault();
    // send api request to validate data
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/task/partial/${id}`,
        {
          statusID: changeStatus,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const responseData = await response;

      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.msg);
      }
      setError(responseData.data.msg);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  };
  //////////////////////////////////////

  //delete task
  const deleteTaskHandler = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/task/action/cancel/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response;

      setError(responseData.data.message);
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      setIsLoading(false);
      setError(err.message || "SomeThing Went Wrong , Please Try Again .");
    }
  };

  //comment validation
  const [commentState, dispatch5] = useReducer(commentReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const commentChangeHandler = (event) => {
    dispatch5({
      type: "CHANGE",
      comment: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const commentTouchHandler = () => {
    dispatch5({
      type: "TOUCH",
    });
  };

  //add Comment
  const addCommentHandler = async (event) => {
    event.preventDefault();
    // send api request to validate data
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/comment/`,
        {
          content: commentState.value,
          task_id: task?._id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const responseData = await response;

      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }

      emitTaskNotification(
        `Task No: ${task.serialNumber} - New Comment Added`,
        task
      );

      setError(responseData.data.message);
      setComments(responseData.data.comments);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  };

  //delete Comment
  const deleteCommentHandler = async (commentId) => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/comment/`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: { commentID: commentId },
        }
      );
      const responseData = await response;
      setError(responseData.data.message);
      setComments(responseData.data.comments);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message || "SomeThing Went Wrong , Please Try Again .");
    }
  };

  //error message
  const errorHandler = () => {
    setError(null);
    window.location.reload(true);
  };

  const calculateCountdown = (deadline) => {
    const now = new Date().getTime();
    const distance = new Date(deadline).getTime() - now;
    if (distance < 0) {
      return "Overdue";
    }
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    if (task && task.deadline) {
      setCountdown(calculateCountdown(task.deadline));
    }
    const interval = setInterval(() => {
      if (task && task.deadline) {
        setCountdown(calculateCountdown(task.deadline));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [task]);

  return isLoading || !task || task.length === 0 ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)] space-y-2">
      <ErrorModal error={error} onClear={errorHandler} />

      <div className="relative flex flex-row justify-center w-full p-1 mb-4">
        <button
          className="absolute top-0 left-0 p-2 text-3xl"
          onClick={() => navigate("/tasks")}
        >
          <TiArrowBack />
        </button>
        <h2 className=" text-2xl font-bold lg:text-3xl">Task Details</h2>
      </div>

      <div className="w-full max-w-5xl 2xl:max-w-6xl mx-auto">
        <div className="flex items-center justify-between bg-white rounded px-8 py-3">
          <div className="font-bold">{task.serialNumber}</div>
          <div
            className={`rounded-md px-4 py-2 text-xs font-bold ${getRowClass(
              task.taskStatus?.statusname
            )} ${getStatusClass(task.taskStatus?.statusname)}`}
          >
            {task.taskStatus?.statusname || "N/A"}
          </div>
          <div className="space-x-2">
            {editTask ? (
              <button onClick={() => setEditTask(false)}>
                <MdCancel className="w-6 h-6" />
              </button>
            ) : (
              <button onClick={() => setEditTask(true)}>
                <MdEdit className="w-6 h-6" />
              </button>
            )}
            <button onClick={deleteTaskHandler}>
              <RiDeleteBinFill className="text-red-500 w-6 h-6" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex w-full max-w-5xl 2xl:max-w-6xl mx-auto space-x-2">
        <div className="w-2/3 bg-white rounded px-8 py-3 drop-shadow">
          {!editTask ? (
            <div className="grid grid-cols-3 gap-2">
              <div className="">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  Title
                </h5>
                <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                  {task.title}
                </p>
              </div>
              <div className="">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  Language
                </h5>
                <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                  {task.language ? task.language : ""}
                </p>
              </div>
              <div className="">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  Speciality
                </h5>
                <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                  {speciality && speciality.sub_speciality}
                </p>
              </div>
              <div className="">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  Channel
                </h5>
                <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                  {task.channel}
                </p>
              </div>
              <div className="">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  Country
                </h5>
                <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                  {task.country && task.country.countryName}
                </p>
              </div>
              <div className="">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  Client
                </h5>
                <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                  <Link
                    className="text-dark fw-bold"
                    to={`/client/${client?._id}`}
                  >
                    {client.clientname}
                  </Link>
                </p>
              </div>

              {task.paid ? (
                <>
                  <div className="">
                    <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                      Freelancer Offer
                    </h5>
                    <p className="font-medium border rounded my-0 mr-0 p-2 flex-1 drop-shadow-sm">
                      ({offer.specialistOfferMax} - {offer.specialistOfferMin})
                    </p>
                  </div>
                  <div className="">
                    <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                      Client Price
                    </h5>
                    <p className="font-medium border rounded my-0 mr-0 p-2 flex-1 drop-shadow-sm">
                      {task.paid}
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  Currency
                </h5>
                <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                  {currency.currencyname}
                </p>
              </div>
              {task.freelancer && (
                <>
                  <div className="">
                    <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                      Freelancer
                    </h5>
                    <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                      <Link
                        className="text-dark fw-bold"
                        to={`/freelancer/${task.freelancer?._id}`}
                      >
                        {task.freelancer.freelancername}
                      </Link>
                    </p>
                  </div>
                </>
              )}

              {task.cost ? (
                <>
                  <div className="">
                    <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                      Client offer
                    </h5>
                    <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                      ({Math.floor(offer.customerOfferMin)} -{" "}
                      {Math.floor(offer.customerOfferMax)})
                    </p>
                  </div>
                  <div className="">
                    <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                      Freelancer Price
                    </h5>
                    <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                      {task.cost}
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}

              {task.profit_amount ? (
                <div className="">
                  <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                    Profit
                  </h5>
                  <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                    {task.profit_amount}
                  </p>
                </div>
              ) : (
                ""
              )}
              <div className="">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  Creator
                </h5>
                <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                  <Link
                    className="text-dark fw-bold"
                    to={`/user/${user && user?._id}`}
                  >
                    {user && user.fullname}
                  </Link>
                </p>
              </div>

              {task.show_created && (
                <div className="">
                  <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                    SharedWith
                  </h5>
                  <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                    {task.show_created.fullname}
                  </p>
                </div>
              )}
              {task.show_accepted && (
                <div className="">
                  <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                    AcceptedWith
                  </h5>
                  <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                    {task.show_accepted.fullname}
                  </p>
                </div>
              )}

              <div className="col-span-3">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  Description
                </h5>
                <p className="font-medium border rounded my-0 mr-0 p-2 flex-1 drop-shadow-sm whitespace-pre-wrap text-right rtl">
                  {task.description.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < task.description.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
              </div>

              <div className="col-span-3">
                <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                  DeadLine
                </h5>
                <div className="flex space-x-2 items-center justify-between">
                  <p className="flex items-center justify-between font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                    <div className="flex space-x-1">
                      <span className="text-danger">Date:</span>
                      <span>
                        {task.deadline && task.deadline.split("T")[0]}
                      </span>
                    </div>
                    <div className="flex space-x-1">
                      <span className="text-danger">Time:</span>
                      <span>
                        {task.deadline &&
                          task.deadline.split("T")[1].split(".")[0]}
                      </span>
                    </div>
                  </p>
                  <p className="space-x-1 font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                    <span className="text-danger">Countdown:</span>
                    <span>{task.deadline && countdown}</span>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <EditTask id={id} token={token} task={task} />
          )}
        </div>
        <div className="w-1/3 flex flex-col">
          <Notes title="Notes" data={notes} height="96" />
          <Notes title="Reviews" data={reviews} height="96" />
        </div>
      </div>

      <Status
        status={task.taskStatus?.statusname}
        task={task}
        setTask={setTask}
        offer={offer}
        setOffer={setOffer}
        percentages={percentages}
      />

      <Attachments task={task} />

      <div className="w-full max-w-5xl 2xl:max-w-6xl mx-auto bg-white drop-shadow rounded p-2">
        <h1 className="text-2xl text-gray-600 font-semibold">Comments</h1>
        <div className="space-y-1.5">
          {!comments.length == 0 ? (
            comments.map((comment) => (
              <div key={comment?._id}>
                <h6 className="m-0 p-0 text-xs text-gray-400">
                  {(comment.user_id && comment.user_id.fullname) || (comment.freelancer_id && comment.freelancer_id.freelancername)} {formatDate(comment.createdAt)}
                </h6>
                <div className="flex items-center space-x-1">
                  {role === "admin" && (
                    <button
                      onClick={() => deleteCommentHandler(comment?._id)}
                      className=""
                    >
                      <IoMdRemoveCircle className="text-red-500" />
                    </button>
                  )}
                  <p className="my-0 ml-2 p-0">{comment.content}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="">
              <p className="">There Is No Comments </p>
            </div>
          )}
        </div>

        <div className="mt-8 flex items-center space-x-2 justify-between">
          <textarea
            type="text"
            placeholder="Add Comment"
            value={commentState.value}
            onChange={commentChangeHandler}
            onBlur={commentTouchHandler}
            isvalid={commentState.isvalid.toString()}
            className={`flex-1 p-2 ${
              !commentState.isvalid && commentState.isTouched && "text-red-500"
            }`}
          />
          <button
            onClick={addCommentHandler}
            disabled={!commentState.isvalid}
            className="w-1/5 py-3.5 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Add Comment
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskDetails;
