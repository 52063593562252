import React, { useState } from "react";
import { Field } from "formik";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { FiCheckCircle } from "react-icons/fi";
import { FiSlash } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import { FiXCircle } from "react-icons/fi";
import { FiClock } from "react-icons/fi";
import { Link } from "react-router-dom";
import axios from "../../../../../axios";
import { emitTaskNotification } from "../../../../../util/io";

const Delivered = ({ taskId, edits = [], setTask, task }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cost, setCost] = useState(0);
  const [note, setNote] = useState("");
  const [deadline, setDeadline] =  useState("");
  const [notIncluded, setNotIncluded] = useState(false);

  const handleDone = async (id) => {
    try {
      setIsLoading(true);
      await axios.post(`/task/action/edit/finish/${id}`, { taskId });
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Edited Is done For This Task`,
        task
      );
      window.location.reload();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.err);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (id, action) => {
    try {
      setIsLoading(true);
      if (action === "included") {
        await axios.post(`/task/action/edit/steps/${id}`, { taskId, status: action, deadline });
        emitTaskNotification(
          `Task No: ${task.serialNumber} - Edit Has been Assigned As included from freelancer`,
          task
        );
      } else if (action === "not included") {
        if (!note || !cost) {
          alert("Please fill all fields");
          return;
        }
        await axios.post(`/task/action/edit/steps/${id}`, { taskId, status: action, note, cost, deadline });
        emitTaskNotification(
          `Task No: ${task.serialNumber} - Edit Has been Assigned As not included from freelancer`,
          task
        );
      }
      window.location.reload();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.err);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <div className="w-full flex items-center justify-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl text-gray-600 font-semibold">Edits</h1>
      </div>
      {edits && edits.length > 0 ? (
        <table className="table-auto border border-collapse w-full text-center">
          <thead>
            <tr>
              <th className="border px-4 py-2 w-3/12">Description</th>
              <th className="border px-4 py-2">Deadline</th>
              <th className="border px-4 py-2">Note</th>
              <th className="border px-4 py-2">Cost</th>
              <th className="border px-4 py-2 w-1/12">Actions</th>
            </tr>
          </thead>
          <tbody>
            {edits.map((edit, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{edit.description}</td>
                <td className="border px-4 py-2">{edit.deadline ? (`${edit.deadline?.split("T")[0]} ${edit.deadline?.split("T")[1]?.split(".")[0]}`) : (
                  <input
                    type="datetime-local"
                    id="meeting-time"
                    name="meeting-time"
                    placeholder="DeadLine"
                    onChange={(e) => setDeadline(e.target.value)}
                    className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
                  />
                )}</td>
                <td className="border px-4 py-2">{edit.status === "pending" ? (
                  <>
                    {notIncluded ? (<input
                      type="text"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      onInput={(e) => setNote(e.target.value)}
                      className="border px-2 py-1"
                    />) : edit.note}
                  </>
                ) : (edit.note)}</td>
                <td className="border px-4 py-2">{edit.status === "pending" ? (
                  <>
                    {notIncluded ? (<input
                      type="number"
                      value={cost}
                      onChange={(e) => setCost(e.target.value)}
                      className="border px-2 py-1"
                    />) : edit.cost}
                  </>
                ) : (edit.cost)}</td>
                <td className="border px-4 py-2">
                  {edit.status === "done" ? (
                    <FiCheck className="text-green-500 w-6 h-6 mx-auto" />
                  ) : edit.status === "delivered" ? (
                    <FiCheckCircle className="text-green-500 w-6 h-6 mx-auto" />
                  ) : edit.status === "pending" ? (
                    <>
                      {notIncluded ? (<><button
                        className="bg-green-500 text-white px-3 py-1 rounded-md"
                        onClick={() => handleEdit(edit?._id, "not included")}
                      >
                        Submit
                      </button>
                      <button
                        className="bg-red-500 text-white px-3 py-1 my-2 rounded-md"
                        onClick={() => setNotIncluded(false)}
                      >
                        Cancel
                      </button></>) : (<><button
                        className="bg-green-500 text-white px-3 py-1 rounded-md"
                        onClick={() => handleEdit(edit?._id, "included")}
                      >
                        Included
                      </button>
                      <button
                        className="bg-red-500 text-white px-3 py-1 my-2 rounded-md"
                        onClick={() => setNotIncluded(true)}
                      >
                        Additional
                      </button></>)}
                    </>
                  ) : edit.status === "included" || edit.status === "accepted" ? (
                    <button
                      className="bg-green-500 text-white px-3 py-1 rounded-md"
                      onClick={() => handleDone(edit?._id)}
                    >
                      Done
                    </button>
                  ) : edit.status === "not included" ? (
                    <FiClock className="text-yellow-500 w-6 h-6 mx-auto" />
                  ) : edit.status === "rejected" ? (
                    <FiXCircle className="text-red-500 w-6 h-6 mx-auto" />
                  ) : (
                    <FiSlash className="text-green-500 w-6 h-6 mx-auto" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center">No edits made</p>
      )}
    </div>
  );
};

export default Delivered;
