import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import Filter from "../../../Filter";
import { useNavigate } from "react-router";
import TasksTable from "../../../TasksTable/TasksTable";
import { useDebounce } from "use-debounce";
import instance from "../../../../axios";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import { FaSearch } from "react-icons/fa";

// statuses filter
const getStatusFilter = (status, tasks) => {
  if (!status) {
    return tasks;
  }
  return tasks.filter((tasks) => tasks.taskStatus?._id.includes(status));
};

const PendingTasks = () => {
  const [tasksCount, setTasksCount] = useState(0);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [search, setSearch] = useState("");
  const [client, setClient] = useState("");
  const [country, setCountry] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [subSpeciality, setSubSpeciality] = useState("");
  const [countries, setCountries] = useState([]);
  const [clients, setClients] = useState([]);
  const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const params = {
          page,
          limit,
          search,
          speciality,
          subSpeciality,
          country,
          client
        };

        console.log(params);
        

        const [specialityRes, countryRes, clientRes, tasksRes] =
          await Promise.all([
            instance.get(`/speciality/`),
            instance.get(`/country/`),
            instance.get(`/client/?dropDown=true`),
            instance.get(`/task/`, {
              params,
            }),
          ]);

        setSpecialities(specialityRes.data.specialities);
        setCountries(countryRes.data.countries);
        setClients(clientRes.data.clients);
        setTasksCount(tasksRes.data.pendingTasksCount);
        setTasks(tasksRes.data.pendingTasks);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    page,
    limit,
    search,
    speciality,
    subSpeciality,
    country,
    client,
  ]);

  const clearFilterHandler = () => {
    setClient("");
    setSearchName("");
    setCountry("");
    setSpeciality("");
    setSubSpeciality("");
  };

  const [filterOpen, setFilterOpen] = useState(false);

  const onFirstPage = () => {
    setPage(1);
  };
  const onLastPage = () => {
    setPage(Math.ceil(tasksCount / limit));
  };
  const onNextPage = () => {
    setPage((prev) => prev + 1);
  };
  const onPreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div
    className={`transition-all min-h-[calc(100vh-65px)] ${
      filterOpen ? "ml-44" : "ml-16"
    } py-4 flex flex-col space-y-2`}
    >
      <ErrorModal message={error} onClear={() => setError(null)} />
      <Filter
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        clear={clearFilterHandler}
      >
        <select
          id="speciality"
          name="speciality"
          className="w-full"
          value={speciality}
          onChange={(e) => {
            setSpeciality(e.target.value);
          }}
        >
          <option selected disabled value="" className="text-secondary">
            Speciality
          </option>
          {specialities.map((speciality, index) => (
            <option key={index} value={speciality.main}>
              {speciality.main}
            </option>
          ))}
        </select>
        {speciality && speciality !== "" && (
          <select
            id="subSpeciality"
            name="subSpeciality"
            className="w-full"
            value={subSpeciality}
            onChange={(e) => {
              setSubSpeciality(e.target.value);
            }}
          >
            <option selected disabled value="" className="text-secondary">
              Sub Speciality
            </option>
            {specialities
              .find((s) => s.main === speciality)
              ?.sub.map((sub) => (
                <option key={sub?._id} value={sub?._id}>
                  {sub.sub_speciality}
                </option>
              ))}
          </select>
        )}

        <select
          id="status"
          name="status"
          className="w-full"
          value={client}
          onChange={(e) => {
            setClient(e.target.value);
          }}
        >
          <option value="" className="text-secondary">
            Clients
          </option>
          {clients.map((client) => (
            <option value={client?._id} key={client?._id}>
              {client?.clientname}
            </option>
          ))}
        </select>

        <select
          id="status"
          name="status"
          className="w-full"
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        >
          <option value="" className="text-secondary">
            Countries
          </option>
          {countries.map((country) => (
            <option value={country?._id} key={country?._id}>
              {country.countryName}
            </option>
          ))}
        </select>
      </Filter>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl">Pending Tasks</h1>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setSearch(e.target.search.value);
        }}
        className="w-1/3 relative"
      >
        <button type="submit" className="absolute top-3 right-3">
          <FaSearch />
        </button>
        <input
          name="search"
          type="text"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          placeholder={"Search By Name or ID"}
        />
      </form>
      {tasks && tasks.length > 0 ? (
        <TasksTable
          pagination
          page={page}
          totalPages={Math.ceil(tasksCount / limit)}
          onFirstPage={onFirstPage}
          onLastPage={onLastPage}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          tasks={tasks}
        />
      ) : (
        <div className="row  p-3 m-0 text-center">
          <h2>There Is No Tasks</h2>
        </div>
      )}
    </div>
  );
};

export default PendingTasks;
