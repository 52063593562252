import React, { useState } from "react";
import { IoMdDownload } from "react-icons/io";
import { Link } from "react-router-dom";
import ErrorModal from "../../LoadingSpinner/ErrorModal";
import instance from "../../axios";
import { format } from 'date-fns';

const AttachmentsTable = ({ attachments, task }) => {
  const role = JSON.parse(localStorage.getItem("user"))?.user_role;
  const user = JSON.parse(localStorage.getItem("user"));
  const [editFull, setEditFull] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm');
  };

  const editFileHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      setError(null);
      const response = await instance.put(
        `/task/action/file/${fileId}`,
        {
          name: fileName,
          description: fileDescription
        }
      );
      if (!(response.statusText === "OK")) {
        throw new Error(response.data.message);
      }
      setError(`${response.data.message}`);
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  }
  
  const deleteFileHandler = async (id) => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await instance.delete(`/task/action/file/${id}`);
      if (!(response.statusText === "OK")) {
        throw new Error(response.data.message);
      }
      setError(`${response.data.message}`);
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  }

  return (
    <>
      <ErrorModal message={error} onClear={() => setError(null)} />
      <table className="w-full mt-2 border border-collapse text-center">
        <thead>
          <tr>
            <th className="py-2 border">File Name</th>
            <th className="py-2 border">Date</th>
            <th className="py-2 border">Uploader</th>
            <th className="py-2 border">Description</th>
            <th className="py-2 border">Action</th>
          </tr>
        </thead>
        <tbody>
          {attachments.map((file, index) => (
            <tr key={`${file.id}-${index}`}>
              <td className="border py-2">
                <Link
                  to={`${process.env.REACT_APP_BACKEND_URL}:5001/api/task/action/download/${file?._id}`}
                  download={file.fileName}
                  className={`text-blue-500 no-underline hover:text-blue-600 flex items-center justify-center space-x-0.5 
                    ${editFull && "hidden"}`}
                >
                  <span>{file.fileName || "N/A"}</span>
                  <IoMdDownload className="mt-1.5" />
                </Link>

                {editFull && (
                  <input
                    type="text"
                    placeholder={file.fileName}
                    onChange={(e) => setFileName(e.target.value)}
                    className={`w-11/12 ml-2 rounded-sm p-2`}
                  />
                )}
              </td>
              <td className="border py-2">
                <span>{formatDate(file.createdAt)}</span>
              </td>
              <td className="border py-2">
                <span>{file.uploader || "N/A"}</span>
              </td>
              <td className="border py-2">
                <p className={`text-center ${editFull && "hidden"}`}>{file.description || "N/A"}</p>

                {editFull && (
                  <input
                    type="text"
                    placeholder={file.description}
                    onChange={(e) => setFileDescription(e.target.value)}
                    className={`w-11/12 ml-2 rounded-sm p-2`}
                  />
                )}
              </td>
              <td className="border py-2">
                {!editFull && (
                  <>
                    {role !== "freelancer" || (role === "freelancer" && file.uploader === user.freelancername) ? (<button
                      className="bg-blue-500 mx-2 rounded-sm transition-all hover:bg-blue-400 text-white px-3 py-1"
                      onClick={() => {
                        setFileId(file?._id);
                        setEditFull(!editFull);
                      }}
                    >
                      Edit
                    </button>) : ""}
                    {role !== "freelancer" || (role === "freelancer" && file.uploader === user.freelancername) ? (<button
                      type="button"
                      className="bg-red-500 mx-2 rounded-sm transition-all hover:bg-green-400 text-white px-3 py-1"
                      onClick={() => {
                        deleteFileHandler(file?._id);
                      }}
                    >
                      Delete
                    </button>) : ""}
                  </>
                )}

                {editFull && fileId === file?._id && (
                  <>
                    <button
                      disabled={
                        !fileId || (!fileName && !fileDescription)
                      }
                      type="button"
                      className="bg-green-500 mx-2 rounded-sm transition-all hover:bg-green-400 text-white px-3 py-1"
                      onClick={editFileHandler}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="bg-red-500 mx-2 rounded-sm transition-all hover:bg-red-400 text-white px-3 py-1"
                      onClick={() => {
                        setEditFull(false);
                      }}
                    >
                      Cancel
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AttachmentsTable;
