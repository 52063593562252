import React, { useState } from "react";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import axios from "../../../../../axios";
import { emitTaskNotification } from "../../../../../util/io";

const InReview = ({ taskId, setStatus, setTask, task }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reviewComment, setReviewComment] = useState("");

  const handleProceed = async () => {
    if (!reviewComment.trim()) {
      alert("Please provide a review comment before proceeding.");
      return;
    }
    try {
      setIsLoading(true);
      await axios.post(`/task/action/review/${taskId}`, { reviewComment });
      setStatus("done");
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Status Changed From In Review To Done`,
        task
      );
      setTask((prev) => ({
        ...prev,
        taskStatus: {
          ...prev.taskStatus,
          statusname: "done",
          slug: "done",
        },
      }));
      window.location.reload();
    } catch (error) {
      console.error("Error proceeding with task:", error);
      alert("An error occurred while proceeding with the task.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReturn = async () => {
    if (!reviewComment.trim()) {
      alert("Please provide a review comment before returning the task.");
      return;
    }
    try {
      setIsLoading(true);
      await axios.post(`/task/action/return/${taskId}`, { reviewComment });
      setStatus("on going");
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Status Changed From In Review To Returned`,
        task
      );
      setTask((prev) => ({
        ...prev,
        taskStatus: {
          ...prev.taskStatus,
          statusname: "on-going",
          slug: "on-going",
        },
      }));
      window.location.reload();
    } catch (error) {
      console.error("Error returning task:", error);
      alert("An error occurred while returning the task.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-lg p-6 space-y-6">
      <h2 className="text-2xl font-bold text-gray-800">Task Review</h2>
      <p className="m-0 p-0 flex flex-col text-blue-500 font-semibold">
        The task has been completed by the freelancer. Please submit your review.
      </p>
      <div className="space-y-4">
        <label htmlFor="reviewComment" className="block text-sm font-medium text-gray-700">
          Review Comment
        </label>
        <textarea
          id="reviewComment"
          rows="4"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Enter your review comments here..."
          value={reviewComment}
          onChange={(e) => setReviewComment(e.target.value)}
        ></textarea>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          onClick={handleReturn}
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
        >
          Return to Freelancer
        </button>
        <button
          onClick={handleProceed}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
        >
          Mark as Done
        </button>
      </div>
    </div>
  );
};

export default InReview;